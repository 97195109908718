import React from 'react'
import Layout from '/src/components/layout'

import { CameraIcon, SupportIcon, CogIcon, ClockIcon } from '@heroicons/react/outline'

import { StaticImage } from "gatsby-plugin-image"
import HolmLaueLogo from "../images/neue_CI_HL_Logo_Normal.png"


const incentives = [
  {
    name: 'Support whenever you need it',
    imageSrc: SupportIcon,
    description: "24 hour 7 days a week breakdown cover, including advice over the phone and emergency call outs.",
  },
  {
    name: 'Spare parts in stock',
    imageSrc: CogIcon,
    description: "It is our policy to ensure we carry a sufficient stock of spare parts.",
  },
  {
    name: 'Same day repair',
    imageSrc: ClockIcon,
    description: "In the unlikely event of a breakdown we aim to be able to get you back up and running on the same day.",
  },

]

export default function Index() {
  return (
    <Layout>
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-50" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <StaticImage
                className="h-full w-full object-cover"
                src="../images/calves-in-middle-field-2.jpg"
                alt="Cows in a field with some trees"
                width={1152}
              />
              <div className="absolute inset-0 bg-gradient-to-r from-brand-dark to-brand-light mix-blend-multiply opacity-20" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">Delivering Technological Advances</span>
                <span className="block text-blue-200">To Farmers and Calf Rearers</span>
              </h1>

              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <a
                    href="/about"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-light-blue-50 sm:px-8"
                  >
                    Learn more
                  </a>
                  <a
                    href="/contact"
                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">About Us</h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Who we are
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="">
                  <StaticImage
                    className="rounded-lg shadow-lg object-cover object-center"
                    src="../images/Fun-at-work.jpg"
                    alt="Holm & Laue Milk Taxi being used by a smiling woman"
                    aspectRatio={12/7}
                  />
                </div>
                <figcaption className="mt-3 flex text-sm text-gray-500">
                  <CameraIcon className="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-2">Holm & Laue Milk Taxi</span>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-gray-500">
                JCC Technologies is a company based in Herefordshire aiming primarily to deliver technological advances to farmers and calf rearers.
              </p>
            </div>
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                Company founder Jon Christopher has been involved in agriculture and agricultural engineering since graduating from Harper Adams in 1998.
              </p>
              <h3>How we can help</h3>
              <p>
                Advances in technology in agriculture have been significant in recent years, automated feeding machines that allow increased production and simultaneous labour savings are at the forefront.
              </p>
              <p>
                Getting the most out of technology is reliant on:
              </p>
              <ul>
                <li>A quality product</li>
                <li>Good installation</li>
                <li>Operator training</li>
                <li>Dealer support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="relative bg-blue-50 pt-16 overflow-hidden sm:pt-24 lg:pt-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <StaticImage
              src="../images/holm-laue/CLAIM_passionforcalves_HL_nebeneinander_schwarz auf weiß.png"
              className="mx-auto mb-6"
              alt="Holm & Laue Passion For Calves logo"
              width={625}
            />
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Our dealer area
            </p>
            <p className="mt-5 max-w-prose mx-auto text-gray-500">
              After several years of installing, servicing, and repairing Holm and Laue products, we are excited to be gaining our own dealer area and can now retail a full range of Holm and Laue products to assist farmers and calf rearers within our dealer area.
            </p>
          </div>
          <div className="my-12 pb-6">
            <StaticImage
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src="../images/gmap.png"
              alt="A map of the JCC Technologies Holm & Laue distribution area"
            />
            <figcaption className="mt-3 flex text-sm text-gray-500">
              <CameraIcon className="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-2">Map data &copy;2021 Google </span>
            </figcaption>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-16 sm:px-2 sm:pt-16 lg:px-4">
          <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
            <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
              <div>
                <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                  We understand the importance of continuity of feeding
                </h2>
                <p className="mt-4 text-gray-500">
                  We offer 24 hour 7 days a week breakdown cover, including advice over the phone and emergency call outs.
                </p>
                <p className="mt-4 text-gray-500">
                  It is our policy to ensure we carry sufficient stock of spare parts, and in the unlikely event of a breakdown we aim to be able to get customers back up and running on the same day.
                </p>
              </div>
              <div className="relative bg-gray-50 rounded-lg overflow-hidden">
                <StaticImage
                  src="../images/cattle-holt-1.JPG"
                  alt="A close up of cattle facing the camera."
                  className="object-center object-cover"
                  aspectRatio={3/2}
                />
              </div>
            </div>
            <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              {incentives.map((incentive) => (
                <div key={incentive.name} className="sm:flex lg:block">
                  <div className="sm:flex-shrink-0">
                    <incentive.imageSrc className="h-16 w-16" aria-hidden="true" />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3 className="font-medium text-gray-900">{incentive.name}</h3>
                    <p className="mt-2 text-gray-500">{incentive.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative mx-auto">
            <img
              className="mx-auto w-64"
              src={HolmLaueLogo}
              alt="Holm & Laue logo"
            />
            <div className="mt-10">
              <div className="max-w-3xl mx-auto text-center prose text-gray-500">
                <p>
                  Please look at the product pages to see information on the Calf Expert Feeder and Milk Taxi.
                  <br/>
                  For any further information please use the ‘contact us’ link to send a message.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="bg-blue-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
            <span className="block">Interested?</span>
            <span className="block text-blue-600">Get in touch.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/contact"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
